import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { AuthContext } from "../../contexts/AuthContext";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function Home() {
  // const { isAuth } = useContext(AuthContext);

  const history = useHistory();

  // function handleClick() {
  //   history.push("/login");
  // }

  function alertaPago(){Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Su cuenta presenta un atraso, le solicitamos realizar el Pago a la brevedad para poder continuar con el servicio",
  });}
alertaPago()
  return (
    <>
       {/* <Header /> */}
      <br />
      <img
        src={process.env.PUBLIC_URL + "/LogoSistemify2.png"}
        height="90%"
        width="90%"
        alt= 'auto'
        className="imgLogo"
        onClick={history.push("/login")}
      />
    </>
  );
}

export default Home;
